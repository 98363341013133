@import "./variables.scss";
@import "./errors.scss";
@import "../layout/Table/table.scss";

.mainToolBar {
  background-color: $primary_color!important;
  color: white;
}

a {
  display: flex;
  flex: 1;
  color: inherit;
  text-decoration: inherit;
}

form {
  .MuiFormControl-root {
    margin-bottom: $spacing_m;
  }
}

.MuiDialogContent-root {
  .MuiDialogContentText-root {
    margin-bottom: $spacing_s;
  }
}

.paperContent {
  padding: $spacing_m;

  .systemSettingEntry {
    display: flex;
    align-items: center;
    margin-top: $spacing_s;
    margin-bottom: $spacing_s;

    b {
      margin-right: $spacing_xs;
    }

    .editIconButton {
      flex: 1;
      text-align: end;
    }
  }
}

.settingsForm {
  padding-top: $spacing_s;
}

.menuItemLink {
  width: 100%;

  li {
    width: 100%;
  }
}

.cardArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cardWrapper {
    padding: $spacing_s $spacing_xs 0;
    box-sizing: border-box;
  }

  .card {
    width: 100%;
  }

  .thirdCard {
    flex-basis: calc(100% / 3);
  }

  .smallCard {
    flex-basis: 20%;
  }

  .mediumCard {
    flex-basis: 30%;
  }

  .largeCard {
    flex-basis: 50%;
  }

  .fullCard {
    flex-basis: 100%;
  }

  .cardHeader {
    display: flex;
    flex-direction: row;

    h1, h2, h3, h4, h5, h6 {
      flex: 1;
    }
  }
}

.textCenter {
  text-align: center;
}

.noBorderTable {
  tbody {
    tr {
      td {
        border-bottom: none;
      }
    }
  }
}

.dividerRow {
  border-bottom: 1px solid rgba(100,100,100, .5);
}

table.compact {
  tbody {
    tr {
      td {
        padding: 0 $spacing_xs;
      }
    }
  }
}

.filterBar {
  margin-top: $spacing_s;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: $spacing_s;
  div {
    flex: 1;
  }
}
