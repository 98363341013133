@import "./../../components/styling/general.scss";

#root {
    background-color: $primary_background_color;
    min-height: 100vh
}

.loginContainer {
    align-items: center;
    justify-content: center;
    height: 100vh;

    .loginBox {
        text-align: center;
        max-width: 75%;
        min-width: 50%;

        .loginTitle {
            margin-bottom: $spacing_l;
        }

        .loginForm {
            display: flex;
            flex-direction: column;
            row-gap: $spacing_m;

            .loginSubmit {
                display: inline-flex;
                align-self: flex-start;
            }
        }
    }

    .loginBelow {
        margin-top: $spacing_l;
        text-align: center;
    }
}


